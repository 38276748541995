import { useLocation } from "react-router";

export const Header = (props: {}) => {
  const page = useLocation().pathname;

  return (
    <header className="flex justify-between bg-white px-8 py-4 text-2xl mb-12 sticky top-0">
      <a href="/">
        <span className="font-bold hover:underline">Marcos Vianna</span>
      </a>

      <div className="space-x-8 text-xl font-semibold text-neutral-400">
        <a className={page === "/" ? "text-black" : ""} href="/">
          Cursos
        </a>
        <a className={page === "/contato" ? "text-black" : ""} href="/contato">
          Contato
        </a>
      </div>
    </header>
  );
};
