import React from "react";
import { HomeIcon } from "./HomeIcon";
import { InternetIcon } from "./InternetIcon";

export const CourseFormat = ({ format }: { format: "online" | "offline" }) => {
  return (
    <span>
      {format === "online" && (
        <span className="flex items-center space-x-1">
          <InternetIcon className="w-4 h-4 stroke-blue-500" />
          <span> Online</span>
        </span>
      )}
      {format === "offline" && (
        <span className="flex items-center space-x-1">
          <HomeIcon className="w-6 h-6 fill-green-500" />
          <span> Presencial</span>
        </span>
      )}
    </span>
  );
};
