import React from "react";

export const HomeIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="84.263mm"
      height="69.261mm"
      version="1.1"
      viewBox="0 0 84.263 69.261"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <metadata></metadata>
      <g transform="translate(-77.358 -201.85)">
        <path
          transform="scale(.26458)"
          d="m451.61 762.91-159.24 133.98 21.939 21.541 137.3-115.53 137.3 115.53 21.939-21.541-44.053-37.066v-89.428h-50v47.357zm0 60-113.38 95.404v106.37h86.111v-78.791h54.549v78.791h86.111v-106.37z"
        />
      </g>
    </svg>
  );
};
