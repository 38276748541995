import React, { useEffect } from "react";
import { useNavigate } from "react-router";

export const PromoPage = (props: {}) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.location.href =
      "https://mailchi.mp/20b2b8117d7c/checklist-para-projeto-de-etas";
  }, []);
  return <></>;
};
