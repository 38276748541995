import { ContactPage } from "./components/ContactPage";
import { Header } from "./components/Header";
import { PromoPage } from "./components/PromoPage";
import { SchedulePage } from "./components/SchedulePage";
import { PageType } from "./contexts/NavContext";

const App = ({ page }: { page: PageType }) => {
  return (
    <>
      <Header />
      <div className="sm:px-8 max-w-7xl m-auto pb-24">
        {page === "/" && <SchedulePage />}
        {page === "/contato" && <ContactPage />}
        {page === "/eta" && <PromoPage />}
      </div>
    </>
  );
};

export default App;
