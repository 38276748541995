import React from "react";

export const InternetIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="30mm"
      height="30mm"
      version="1.1"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g transform="translate(-61.483 -10.367)">
        <g fill="none">
          <circle
            transform="scale(1,-1)"
            cx="76.483"
            cy="-25.367"
            r="13.899"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.2015"
          />
          <ellipse
            transform="scale(1,-1)"
            cx="76.483"
            cy="-25.367"
            rx="6.9083"
            ry="13.596"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.3237"
          />
          <path d="m76.483 11.77v27.192" strokeWidth=".26458px" />
          <g strokeWidth="2.1167">
            <path d="m62.584 24.806h27.798" />
            <path d="m64.7 17.794h23.565" />
            <path d="m64.7 31.818h23.565" />
            <path d="m76.483 11.77v27.192" />
            <path d="m62.584 24.806h27.798" />
            <path d="m64.7 17.794h23.565" />
            <path d="m64.7 31.818h23.565" />
          </g>
        </g>
      </g>
    </svg>
  );
};
