import { CourseFormat } from "./CourseFormat";
import { EmailSignupForm } from "./EmailSignupForm";
import { Table } from "./table/Table";
import { TBody } from "./table/TBody";
import { Td } from "./table/Td";
import { Th } from "./table/Th";
import { THead } from "./table/THead";
import { Tr } from "./table/Tr";
import PhotoMarcos from "../images/marcos.jpg";

type Course = {
  date: string;
  name: string;
  format: "online" | "offline";
  link: string;
};

const COURSES: Course[] = [
  {
    date: "22/01/2024",
    name: "Abastecimento de água",
    format: "online",
    link: "https://www.sympla.com.br/abastecimento-de-agua-curso-online__2154892",
  },
  {
    date: "19/02/2024",
    name: "Adutoras de sistemas de abastecimento de água",
    format: "online",
    link: "https://www.sympla.com.br/adutoras-de-sistemas-de-abastecimento-de-agua__2245349",
  },
  {
    date: "11/03/2024",
    name: "Bombas Centrífugas e Motores Elétricos",
    format: "online",
    link: "https://www.sympla.com.br/bombas-centrifugas-e-motores-eletricos-curso-online__2245352",
  },
  {
    date: "15/04/2024",
    name: "Bombas e Sistemas de Recalque",
    format: "online",
    link: "https://www.sympla.com.br/bombas-e-sistemas-de-recalque__2245343",
  },
  {
    date: "06/05/2024",
    name: "Conhecendo o tratamento da água Módulo 1: unidades componentes da ETA",
    format: "online",
    link: "https://www.sympla.com.br/conhecendo-o-tratamento-da-agua--modulo-1-unidades-componentes-da-eta__2245737",
  },
  {
    date: "20/05/2024",
    name: "Conhecendo o tratamento da água Módulo 2: produtos químicos",
    format: "online",
    link: "https://www.sympla.com.br/conhecendo-o-tratamento-da-agua--modulo-2-produtos-quimicos__2245753",
  },
  {
    date: "10/06/2024",
    name: "Golpe de Aríete em Adutoras",
    format: "online",
    link: "https://www.sympla.com.br/golpe-de-ariete-em-adutoras-curso-online__2245765",
  },
  {
    date: "08/07/2024",
    name: "Irrigação de pomar com esgoto tratado",
    format: "online",
    link: "https://www.sympla.com.br/irrigacao-de-pomar-com-esgoto-tratado__2245769",
  },
  {
    date: "09/09/2024",
    name: "Bombas Centrífugas e Motores Elétricos",
    format: "online",
    link: "https://www.sympla.com.br/bombas-centrifugas-e-motores-eletricos-curso-online__2245810",
  },
  {
    date: "07/10/2024",
    name: "Bombas e Sistemas de Recalque",
    format: "online",
    link: "https://www.sympla.com.br/bombas-e-sistemas-de-recalque__2245819",
  },
  {
    date: "11/11/2024",
    name: "Conhecendo o tratamento da água Módulo 1: unidades componentes da ETA",
    format: "online",
    link: "https://www.sympla.com.br/conhecendo-o-tratamento-da-agua--modulo-1-unidades-componentes-da-eta__2245825",
  },
  {
    date: "18/11/2024",
    name: "Conhecendo o tratamento da água Módulo 2: produtos químicos",
    format: "online",
    link: "https://www.sympla.com.br/conhecendo-o-tratamento-da-agua--modulo-2-produtos-quimicos-copia__2245833",
  },
  {
    date: "22/11/2023",
    name: "Hidráulica Aplicada às Estações de Tratamento de Água",
    format: "offline",
    link: "https://www.hidraulicaeta.com/",
  },
  {
    date: "09/12/2024",
    name: "Golpe de Aríete em Adutoras",
    format: "online",
    link: "https://www.sympla.com.br/golpe-de-ariete-em-adutoras-curso-online__2245755",
  },
];

export const SchedulePage = (props: {}) => {
  return (
    <div className="space-y-16 flex flex-col">
      <div className="text-center md:text-left md:flex md:flex-row items-center space-x-6">
        <img
          alt="Fotografia do prof. Marcos Vianna"
          src={PhotoMarcos}
          className="m-auto rounded-full w-48 border border-8 border-white shadow-lg"
        />
        <div className="text-lg space-y-2">
          <h1 className="font-semibold text-2xl">
            Olá, aqui é o professor Marcos.
          </h1>
          <h2 className="text-neutral-600">
            <span>
              Sou professor de hidráulica e saneamento há mais de 40 anos em
              universidades federais e públicas. Também atuo como consultor
              através da <b>Bloom Consultoria</b>.
            </span>
          </h2>
          <h2 className="text-neutral-600">
            <span>
              Na tabela abaixo você vai encontrar a programação dos meus cursos
              previstos para este ano. Você pode também entrar em contato comigo
              <a
                className="text-blue-700 font-semibold hover:cursor-pointer"
                href="/contato"
              >
                {" "}
                clicando aqui
              </a>
              .
            </span>
          </h2>
        </div>
      </div>

      <Table>
        <THead>
          <Tr>
            <Th>Data</Th>
            <Th>Curso</Th>
            <Th>Formato</Th>
            <Th></Th>
          </Tr>
        </THead>
        <TBody>
          {COURSES.map((course, index) => {
            return (
              <Tr key={index}>
                <Td>{course.date}</Td>
                <Td>
                  {course.link && (
                    <a
                      href={course.link}
                      className="hover:underline text-blue-700"
                    >
                      {course.name}
                    </a>
                  )}
                  {!course.link && <span>{course.name}</span>}
                </Td>
                <Td>
                  <CourseFormat format={course.format} />
                </Td>
                <Td>
                  {course.link && (
                    <form action={course.link}>
                      <input
                        className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-500 hover:cursor-pointer"
                        type="submit"
                        value="Quero saber mais"
                        formTarget="_blank"
                      />
                    </form>
                  )}
                  {!course.link && (
                    <input
                      className="bg-neutral-400 text-white px-4 py-2 rounded-md "
                      type="submit"
                      value="Link em breve"
                      formTarget="_blank"
                      disabled
                    />
                  )}
                </Td>
              </Tr>
            );
          })}
        </TBody>
      </Table>
      <div className="space-y-4 px-4">
        <div className="text-2xl font-semibold">
          <span>Quer ser avisado de novos cursos? </span>{" "}
          <span>Entre com o seu nome e e-mail aqui:</span>
        </div>
        <EmailSignupForm />
      </div>
    </div>
  );
};
