import React from "react";

export const EmailSignupForm = (props: {}) => {
  return (
    <>
      {/* Begin Mailchimp Signup Form */}
      <div id="mc_embed_signup">
        <form
          action="https://facebook.us16.list-manage.com/subscribe/post?u=e11936a6385f5997ad9c55f27&id=026449b7f6&f_id=003e5fe0f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate flex"
          target="_self"
        >
          <div
            id="mc_embed_signup_scroll "
            className="  md:flex md:flex-row md:space-x-1 space-y-2 grow items-center"
          >
            <h2 />
            <div className="mc-field-group text-lg grow basis-2">
              <input
                type="email"
                defaultValue=""
                name="EMAIL"
                className="required email px-2 py-1 border border-neutral-400 w-full"
                id="mce-EMAIL"
                placeholder="E-mail"
                required
              />
              <span id="mce-EMAIL-HELPERTEXT" className="helper_text" />
            </div>
            <div className="mc-field-group text-lg space-x-2 grow basis-1">
              <input
                type="text"
                defaultValue=""
                name="FNAME"
                className="px-2 py-1 border border-neutral-400 w-full"
                placeholder="Nome"
                id="mce-FNAME"
              />
              <span id="mce-FNAME-HELPERTEXT" className="helper_text" />
            </div>
            <div id="mce-responses" className="clear foot">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              />
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              />
            </div>{" "}
            {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_e11936a6385f5997ad9c55f27_026449b7f6"
                tabIndex={-1}
                defaultValue=""
              />
            </div>
            <div className="optionalParent grow basis-1">
              <div className="clear foot">
                <input
                  type="submit"
                  defaultValue="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button bg-orange-600 font-bold w-full rounded-md text-white  text-lg px-8 py-2"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      {/*End mc_embed_signup*/}
    </>
  );
};
